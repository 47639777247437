import { Routing } from "../shared/constants/routing";

export const itemsPerPageList = [
  {
    id: 1,
    label: "3 Items Per Page",
    value: 3,
  },
  {
    id: 2,
    label: "5 Items Per Page",
    value: 5,
  },
  {
    id: 3,
    label: "10 Items Per Page",
    value: 10,
  },
  {
    id: 4,
    label: "25 Items Per Page",
    value: 25,
  },
  {
    id: 5,
    label: "50 Items Per Page",
    value: 50,
  },
  {
    id: 6,
    label: "100 Items Per Page",
    value: 100,
  },
  {
    id: 7,
    label: "200 Items Per Page",
    value: 200,
  },
];

export const ownerPath = [
                          Routing?.OwnerDashBoard, 
                          Routing?.OwnerReport, 
                          Routing?.HotelWiseOwnerReport,
                          // Routing?.Reports,
                          Routing?.OwnerIframeReport
                        ];

export const houseKeeper = [
  Routing?.HouseKeeper, 
  Routing?.HouseKeeperCleanRoom, 
]                        

export const rowsPerPageJsonData = [10, 20, 50, 100];

export const cancellationPolicyOption = [
  {
    id: 1,
    label: "Free cancellation, full refund - up to 24hr before checkin",
  },
  {
    id: 3,
    label: "Free cancellation, full refund - up to 72hr before checkin",
  },
  { id: 2, label: "Non-refundable" },
];
export const productTypes = [
  { id: 1, label: "Type 1" },
  { id: 2, label: "Type 2" },
  { id: 3, label: "Type 3" },
  { id: 4, label: "Type 4" },
  { id: 5, label: "Type 5" },
];

export const roomStatusJsonData = [
  { id: 1, name: "Available" },
  { id: 2, name: "Not Available" },
  { id: 3, name: "Occupied" },
  { id: 5, name: "Out Of Service" },
  // { id: 4, name: "Need Cleaning" },
  // { id: 5, name: "Cleaning In Progress" },
];
export const houseKeepingJsonData = [
  { id: 1, name: "Cleaning In Progress" },
  { id: 2, name: "Clean" },
  { id: 3, name: "Dirty" },
  { id: 4, name: "Freshenup" },
];

export const adrFilterDropdownData = [
  { id: 1, name: "Custom" },
  { id: 2, name: "Monthly" },
  { id: 3, name: "Quarterly" },
  { id: 4, name: "Annually" },
  // { id: 5, name: "Biannually" },
];

export const priceDayNames = [
  "monday_price",
  "tuesday_price",
  "wednesday_price",
  "thursday_price",
  "friday_price",
  "saturday_price",
  "sunday_price",
];

export const mealDropdownData = [
  { id: 1, name: "Crib" },
  { id: 2, name: "Rollaway" },
  { id: 3, name: "Suppress Rate" },
  { id: 4, name: "meal" },
];

export const bookARoomTableHeadingData = [
  {
    name: "Room Type",
  },
  {
    name: "Dates",
  },
  {
    name: "Room rate",
  },
  {
    name: "Add On",
  },
  {
    name: "Discounted Rate",
  },
  // {
  //   name: "Tax Exemption",
  // },
  {
    name: "Cancellation policy",
  },
  {
    name: "Quantity",
  },
  {
    name: "Price for room",
  },
  // {
  //   name: "Discount",
  // },
  {
    name: "Discounted Price",
  },
  {
    name: "Add On Price",
  },
  {
    name: "",
  },
];

export const confirmBookingTableHeadingData = [
  {
    name: "Room Type",
  },
  {
    name: "Dates",
  },
  {
    name: "Nights",
  },
  {
    name: "Room rate",
  },
  {
    name: "Add On",
  },
  {
    name: "Discounted Rate",
  },
  // {
  //   name: "Tax Exemption",
  // },
  {
    name: "Cancellation policy",
  },
  {
    name: "Quantity",
  },
  {
    name: "Price for room",
  },
  {
    name: "Discount",
  },
  {
    name: "Discounted price",
  },
  {
    name: "Add On Price",
  },
  {
    name: "",
  },
];

export const LanguageCode = [
  {
    value: "ar",
    name: "Arabic",
  },
  {
    value: "az",
    name: "Azerbaijani",
  },
  {
    value: "bg",
    name: "Bulgarian",
  },
  {
    value: "ca",
    name: "Catalan",
  },
  {
    value: "xt",
    name: "Chinese (Cantonese)",
  },
  {
    value: "zh",
    name: "Chinese (Mandarin)",
  },
  {
    value: "zh-cn",
    name: "Chinese (Simplified)",
  },
  {
    value: "zh-tw",
    name: "Chinese (Traditional)",
  },
  {
    value: "hr",
    name: "Croatian",
  },
  {
    value: "cs",
    name: "Czech",
  },
  {
    value: "da",
    name: "Danish",
  },
  {
    value: "nl",
    name: "Dutch",
  },
  {
    value: "en-us",
    name: "English (American)",
  },
  {
    value: "en",
    name: "English (UK)",
  },
  {
    value: "et",
    name: "Estonian",
  },
  {
    value: "fi",
    name: "Finnish",
  },
  {
    value: "fr",
    name: "French",
  },
  {
    value: "de",
    name: "German",
  },
  {
    value: "el",
    name: "Greek",
  },
  {
    value: "he",
    name: "Hebrew",
  },
  {
    value: "hi",
    name: "Hindi",
  },
  {
    value: "hu",
    name: "Hungarian",
  },
  {
    value: "is",
    name: "Icelandic",
  },
  {
    value: "id",
    name: "Indonesian",
  },
  {
    value: "it",
    name: "Italian",
  },
  {
    value: "ja",
    name: "Japanese",
  },
  {
    value: "km",
    name: "Khmer",
  },
  {
    value: "ko",
    name: "Korean",
  },
  {
    value: "lo",
    name: "Lao",
  },
  {
    value: "lv",
    name: "Latvian",
  },
  {
    value: "lt",
    name: "Lithuanian",
  },
  {
    value: "ms",
    name: "Malay",
  },
  {
    value: "no",
    name: "Norwegian",
  },
  {
    value: "pl",
    name: "Polish",
  },
  {
    value: "pt",
    name: "Portuguese",
  },
  {
    value: "pt-br",
    name: "Portuguese (Brazilian)",
  },
  {
    value: "ro",
    name: "Romanian",
  },
  {
    value: "ru",
    name: "Russian",
  },
  {
    value: "sr",
    name: "Serbian",
  },
  {
    value: "sk",
    name: "Slovak",
  },
  {
    value: "sl",
    name: "Slovenian",
  },
  {
    value: "es",
    name: "Spanish",
  },
  {
    value: "es-ar",
    name: "Spanish (Argentine)",
  },
  {
    value: "xs",
    name: "Spanish (South American)",
  },
  {
    value: "sv",
    name: "Swedish",
  },
  {
    value: "tl",
    name: "Tagalog",
  },
  {
    value: "th",
    name: "Thai",
  },
  {
    value: "tr",
    name: "Turkish",
  },
  {
    value: "uk",
    name: "Ukrainian",
  },
  {
    value: "vi",
    name: "Vietnamese",
  },
];

export const CurrencyCode = [
  {
    value: "AED",
    name: "United Arab Emirates",
  },
  {
    value: "AFN",
    name: "Afghanistan",
  },
  {
    value: "ALL",
    name: "Albania",
  },
  {
    value: "AMD",
    name: "Armenia",
  },
  {
    value: "ANG",
    name: "Netherlands Antilles",
  },
  {
    value: "ANG",
    name: "Sint Maarten",
  },
  {
    value: "AOA",
    name: "Angola",
  },
  {
    value: "ARS",
    name: "Argentina",
  },
  {
    value: "AUD",
    name: "Australia",
  },
  {
    value: "AUD",
    name: "Christmas Island",
  },
  {
    value: "AUD",
    name: "Cocos Islands",
  },
  {
    value: "AUD",
    name: "Kiribati",
  },
  {
    value: "AUD",
    name: "Nauru",
  },
  {
    value: "AUD",
    name: "Norfolk Island",
  },
  {
    value: "AWG",
    name: "Aruba",
  },
  {
    value: "AZN",
    name: "Azerbaijan",
  },
  {
    value: "BAM",
    name: "Bosnia and Herzegovina",
  },
  {
    value: "BBD",
    name: "Barbados",
  },
  {
    value: "BDT",
    name: "Bangladesh",
  },
  {
    value: "BGN",
    name: "Bulgaria",
  },
  {
    value: "BHD",
    name: "Bahrain",
  },
  {
    value: "BIF",
    name: "Burundi",
  },
  {
    value: "BMD",
    name: "Bermuda",
  },
  {
    value: "BND",
    name: "Brunei Darussalam",
  },
  {
    value: "BOB",
    name: "Bolivia",
  },
  {
    value: "BRL",
    name: "Brazil",
  },
  {
    value: "BSD",
    name: "Bahamas",
  },
  {
    value: "BTN",
    name: "Bhutan",
  },
  {
    value: "BWP",
    name: "Botswana",
  },
  {
    value: "BYR",
    name: "Belarus",
  },
  {
    value: "BZD",
    name: "Belize",
  },
  {
    value: "CAD",
    name: "Canada",
  },
  {
    value: "CDF",
    name: "Democratic Republic of the Congo",
  },
  {
    value: "CFA",
    name: "franc XAF Gabon",
  },
  {
    value: "CHF",
    name: "Switzerland",
  },
  {
    value: "CHF",
    name: "Liechtenstein",
  },
  {
    value: "CLP",
    name: "Chile",
  },
  {
    value: "CNY",
    name: "China",
  },
  {
    value: "COP",
    name: "Colombia",
  },
  {
    value: "CRC",
    name: "Costa Rica",
  },
  {
    value: "CUP",
    name: "Cuba",
  },
  {
    value: "CVE",
    name: "Cape Verde",
  },
  {
    value: "CZK",
    name: "Czech Republic",
  },
  {
    value: "DJF",
    name: "Djibouti",
  },
  {
    value: "DKK",
    name: "Denmark",
  },
  {
    value: "DKK",
    name: "Faroe Islands",
  },
  {
    value: "DKK",
    name: "Greenland",
  },
  {
    value: "DOP",
    name: "Dominican Republic",
  },
  {
    value: "DZD",
    name: "Algeria",
  },
  {
    value: "EEK",
    name: "Estonia",
  },
  {
    value: "EGP",
    name: "Egypt",
  },
  {
    value: "ERN",
    name: "Eritrea",
  },
  {
    value: "ETB",
    name: "Ethiopia",
  },
  {
    value: "EUR",
    name: "Germany",
  },
  {
    value: "EUR",
    name: "Greece",
  },
  {
    value: "EUR",
    name: "Iceland",
  },
  {
    value: "EUR",
    name: "Andorra",
  },
  {
    value: "EUR",
    name: "Austria",
  },
  {
    value: "EUR",
    name: "Belgium",
  },
  {
    value: "EUR",
    name: "Cyprus",
  },
  {
    value: "EUR",
    name: "Finland",
  },
  {
    value: "EUR",
    name: "France",
  },
  {
    value: "EUR",
    name: "Ireland",
  },
  {
    value: "EUR",
    name: "Italy",
  },
  {
    value: "EUR",
    name: "Luxembourg",
  },
  {
    value: "EUR",
    name: "Malta",
  },
  {
    value: "EUR",
    name: "Monaco",
  },
  {
    value: "EUR",
    name: "Netherlands",
  },
  {
    value: "EUR",
    name: "Portugal",
  },
  {
    value: "EUR",
    name: "San Marino",
  },
  {
    value: "EUR",
    name: "Slovakia",
  },
  {
    value: "EUR",
    name: "Slovenia",
  },
  {
    value: "EUR",
    name: "Spain",
  },
  {
    value: "EUR",
    name: "Vatican City",
  },
  {
    value: "EUR",
    name: "Turkey",
  },
  {
    value: "EUR",
    name: "Guadeloupe",
  },
  {
    value: "EUR",
    name: "Kosovo",
  },
  {
    value: "EUR",
    name: "Martinique",
  },
  {
    value: "EUR",
    name: "Mayotte",
  },
  {
    value: "EUR",
    name: "Montenegro",
  },
  {
    value: "EUR",
    name: "Reunion",
  },
  {
    value: "EUR",
    name: "Saint Barthelemy",
  },
  {
    value: "EUR",
    name: "Saint Martin",
  },
  {
    value: "EUR",
    name: "Saint Pierre and Miquelon",
  },
  {
    value: "FJD",
    name: "Fiji",
  },
  {
    value: "FKP",
    name: "Falkland Islands",
  },
  {
    value: "GBP",
    name: "United Kingdom",
  },
  {
    value: "GBP",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "GEL",
    name: "Georgia",
  },
  {
    value: "GGP",
    name: "Guernsey",
  },
  {
    value: "GHC",
    name: "Ghana",
  },
  {
    value: "GIP",
    name: "Gibraltar",
  },
  {
    value: "GMD",
    name: "Gambia",
  },
  {
    value: "GNF",
    name: "Guinea",
  },
  {
    value: "GTQ",
    name: "Guatemala",
  },
  {
    value: "GYD",
    name: "Guyana",
  },
  {
    value: "HKD",
    name: "Hong Kong",
  },
  {
    value: "HNL",
    name: "Honduras",
  },
  {
    value: "HRK",
    name: "Croatia",
  },
  {
    value: "HTG",
    name: "Haiti",
  },
  {
    value: "HUF",
    name: "Hungary",
  },
  {
    value: "IDR",
    name: "Indonesia",
  },
  {
    value: "ILS",
    name: "Israel",
  },
  {
    value: "ILS",
    name: "Palestinian Territory",
  },
  {
    value: "IMP",
    name: "Isle of Man",
  },
  {
    value: "INR",
    name: "India",
  },
  {
    value: "IQD",
    name: "Iraq",
  },
  {
    value: "IRR",
    name: "Iran",
  },
  {
    value: "JEP",
    name: "Jersey",
  },
  {
    value: "JMD",
    name: "Jamaica",
  },
  {
    value: "JOD",
    name: "Jordan",
  },
  {
    value: "JPY",
    name: "Japan",
  },
  {
    value: "KES",
    name: "Kenya",
  },
  {
    value: "KGS",
    name: "Kyrgyzstan",
  },
  {
    value: "KHR",
    name: "Cambodia",
  },
  {
    value: "KMF",
    name: "Comoros",
  },
  {
    value: "KPW",
    name: "North Korea",
  },
  {
    value: "KPW",
    name: "Korea",
  },
  {
    value: "KRW",
    name: "South Korea",
  },
  {
    value: "KWD",
    name: "Kuwait",
  },
  {
    value: "KYD",
    name: "Cayman Islands",
  },
  {
    value: "KZT",
    name: "Kazakhstan",
  },
  {
    value: "LAK",
    name: "Laos",
  },
  {
    value: "LBP",
    name: "Lebanon",
  },
  {
    value: "LKR",
    name: "Sri Lanka",
  },
  {
    value: "LRD",
    name: "Liberia",
  },
  {
    value: "LSL",
    name: "Lesotho",
  },
  {
    value: "LTL",
    name: "Lithuania",
  },
  {
    value: "LVL",
    name: "Latvia",
  },
  {
    value: "LYD",
    name: "Libya",
  },
  {
    value: "MAD",
    name: "Morocco",
  },
  {
    value: "MDL",
    name: "Moldova",
  },
  {
    value: "MGA",
    name: "Madagascar",
  },
  {
    value: "MKD",
    name: "Macedonia",
  },
  {
    value: "MMK",
    name: "Myanmar",
  },
  {
    value: "MNT",
    name: "Mongolia",
  },
  {
    value: "MOP",
    name: "Macau",
  },
  {
    value: "MRO",
    name: "Mauritania",
  },
  {
    value: "MUR",
    name: "Mauritius",
  },
  {
    value: "MVR",
    name: "Maldives",
  },
  {
    value: "MWK",
    name: "Malawi",
  },
  {
    value: "MXN",
    name: "Mexico",
  },
  {
    value: "MYR",
    name: "Malaysia",
  },
  {
    value: "MZN",
    name: "Mozambique",
  },
  {
    value: "NAD",
    name: "Namibia",
  },
  {
    value: "NGN",
    name: "Nigeria",
  },
  {
    value: "NIO",
    name: "Nicaragua",
  },
  {
    value: "NOK",
    name: "Norway",
  },
  {
    value: "NPR",
    name: "Nepal",
  },
  {
    value: "NZD",
    name: "Niue",
  },
  {
    value: "NZD",
    name: "Cook Islands",
  },
  {
    value: "NZD",
    name: "Pitcairn",
  },
  {
    value: "NZD",
    name: "Tokelau",
  },
  {
    value: "OMR",
    name: "Oman",
  },
  {
    value: "PAB",
    name: "Panama",
  },
  {
    value: "PEN",
    name: "Peru",
  },
  {
    value: "PGK",
    name: "Papua New Guinea",
  },
  {
    value: "PHP",
    name: "Philippines",
  },
  {
    value: "PKR",
    name: "Pakistan",
  },
  {
    value: "PLN",
    name: "Poland",
  },
  {
    value: "PYG",
    name: "Paraguay",
  },
  {
    value: "QAR",
    name: "Qatar",
  },
  {
    value: "RON",
    name: "Romania",
  },
  {
    value: "RSD",
    name: "Serbia",
  },
  {
    value: "RUB",
    name: "Russia",
  },
  {
    value: "RWF",
    name: "Rwanda",
  },
  {
    value: "SAR",
    name: "Saudi Arabia",
  },
  {
    value: "SBD",
    name: "Solomon Islands",
  },
  {
    value: "SCR",
    name: "Seychelles",
  },
  {
    value: "SDG",
    name: "Sudan",
  },
  {
    value: "SEK",
    name: "Sweden",
  },
  {
    value: "SGD",
    name: "Singapore",
  },
  {
    value: "SHP",
    name: "Saint Helena",
  },
  {
    value: "SOS",
    name: "Somalia",
  },
  {
    value: "SRD",
    name: "Suriname",
  },
  {
    value: "SSP",
    name: "South Sudan",
  },
  {
    value: "USD",
    name: "United States",
  },
];

export const countryNameList = [
  {
    name: "AD",
    value: "Andorra",
  },
  {
    name: "AE",
    value: "United Arab Emirates",
  },
  {
    name: "AF",
    value: "Afghanistan",
  },
  {
    name: "AG",
    value: "Antigua and Barbuda",
  },
  {
    name: "AL",
    value: "Albania",
  },
  {
    name: "AM",
    value: "Armenia",
  },
  {
    name: "AN",
    value: "Netherlands Antilles",
  },
  {
    name: "AO",
    value: "Angola",
  },
  {
    name: "AR",
    value: "Argentina",
  },
  {
    name: "AT",
    value: "Austria",
  },
  {
    name: "AU",
    value: "Australia",
  },
  {
    name: "AW",
    value: "Aruba",
  },
  {
    name: "AZ",
    value: "Azerbaijan",
  },
  {
    name: "BA",
    value: "Bosnia and Herzegovina",
  },
  {
    name: "BB",
    value: "Barbados",
  },
  {
    name: "BD",
    value: "Bangladesh",
  },
  {
    name: "BE",
    value: "Belgium",
  },
  {
    name: "BF",
    value: "Burkina Faso",
  },
  {
    name: "BG",
    value: "Bulgaria",
  },
  {
    name: "BH",
    value: "Bahrain",
  },
  {
    name: "BI",
    value: "Burundi",
  },
  {
    name: "BJ",
    value: "Benin",
  },
  {
    name: "BL",
    value: "Saint Barthelemy",
  },
  {
    name: "BM",
    value: "Bermuda",
  },
  {
    name: "BN",
    value: "Brunei Darussalam",
  },
  {
    name: "BO",
    value: "Bolivia",
  },
  {
    name: "BQ",
    value: "Bonaire Sint Eustatius and Saba",
  },
  {
    name: "BR",
    value: "Brazil",
  },
  {
    name: "BS",
    value: "Bahamas",
  },
  {
    name: "BT",
    value: "Bhutan",
  },
  {
    name: "BW",
    value: "Botswana",
  },
  {
    name: "BY",
    value: "Belarus",
  },
  {
    name: "BZ",
    value: "Belize",
  },
  {
    name: "CA",
    value: "Canada",
  },
  {
    name: "CC",
    value: "Cocos Islands",
  },
  {
    name: "CD",
    value: "Democratic Republic of the Congo",
  },
  {
    name: "CF",
    value: "Central African Republic",
  },
  {
    name: "CG",
    value: "Republic of the Congo",
  },
  {
    name: "CH",
    value: "Switzerland",
  },
  {
    name: "CK",
    value: "Cook Islands",
  },
  {
    name: "CL",
    value: "Chile",
  },
  {
    name: "CN",
    value: "China",
  },
  {
    name: "CO",
    value: "Colombia",
  },
  {
    name: "CR",
    value: "Costa Rica",
  },
  {
    name: "CU",
    value: "Cuba",
  },
  {
    name: "CV",
    value: "Cape Verde",
  },
  {
    name: "CX",
    value: "Christmas Island",
  },
  {
    name: "CY",
    value: "Cyprus",
  },
  {
    name: "CZ",
    value: "Czech Republic",
  },
  {
    name: "DE",
    value: "Germany",
  },
  {
    name: "DJ",
    value: "Djibouti",
  },
  {
    name: "DK",
    value: "Denmark",
  },
  {
    name: "DM",
    value: "Dominica",
  },
  {
    name: "DO",
    value: "Dominican Republic",
  },
  {
    name: "DZ",
    value: "Algeria",
  },
  {
    name: "EC",
    value: "Ecuador",
  },
  {
    name: "EE",
    value: "Estonia",
  },
  {
    name: "EG",
    value: "Egypt",
  },
  {
    name: "ER",
    value: "Eritrea",
  },
  {
    name: "ES",
    value: "Spain",
  },
  {
    name: "ET",
    value: "Ethiopia",
  },
  {
    name: "FI",
    value: "Finland",
  },
  {
    name: "FJ",
    value: "Fiji",
  },
  {
    name: "FK",
    value: "Falkland Islands",
  },
  {
    name: "FM",
    value: "Micronesia",
  },
  {
    name: "FO",
    value: "Faroe Islands",
  },
  {
    name: "FR",
    value: "France",
  },
  {
    name: "GA",
    value: "franc XAF Gabon",
  },
  {
    name: "GB",
    value: "United Kingdom",
  },
  {
    name: "GD",
    value: "Grenada",
  },
  {
    name: "GE",
    value: "Georgia",
  },
  {
    name: "GG",
    value: "Guernsey",
  },
  {
    name: "GH",
    value: "Ghana",
  },
  {
    name: "GI",
    value: "Gibraltar",
  },
  {
    name: "GL",
    value: "Greenland",
  },
  {
    name: "GM",
    value: "Gambia",
  },
  {
    name: "GN",
    value: "Guinea",
  },
  {
    name: "GP",
    value: "Guadeloupe",
  },
  {
    name: "GQ",
    value: "Equatorial Guinea",
  },
  {
    name: "GR",
    value: "Greece",
  },
  {
    name: "GS",
    value: "South Georgia and the South Sandwich Islands",
  },
  {
    name: "GT",
    value: "Guatemala",
  },
  {
    name: "GU",
    value: "Guam",
  },
  {
    name: "GW",
    value: "Guinea-Bissau",
  },
  {
    name: "GY",
    value: "Guyana",
  },
  {
    name: "HK",
    value: "Hong Kong",
  },
  {
    name: "HN",
    value: "Honduras",
  },
  {
    name: "HR",
    value: "Croatia",
  },
  {
    name: "HT",
    value: "Haiti",
  },
  {
    name: "HU",
    value: "Hungary",
  },
  {
    name: "ID",
    value: "Indonesia",
  },
  {
    name: "IL",
    value: "Ireland",
  },
  {
    name: "IL",
    value: "Israel",
  },
  {
    name: "IM",
    value: "Isle of Man",
  },
  {
    name: "IN",
    value: "India",
  },
  {
    name: "IO",
    value: "British Indian Ocean Territory",
  },
  {
    name: "IQ",
    value: "Iraq",
  },
  {
    name: "IR",
    value: "Iran",
  },
  {
    name: "IS",
    value: "Iceland",
  },
  {
    name: "IT",
    value: "Italy",
  },
  {
    name: "JE",
    value: "Jersey",
  },
  {
    name: "JM",
    value: "Jamaica",
  },
  {
    name: "JO",
    value: "Jordan",
  },
  {
    name: "JP",
    value: "Japan",
  },
  {
    name: "KE",
    value: "Kenya",
  },
  {
    name: "KG",
    value: "Kyrgyzstan",
  },
  {
    name: "KH",
    value: "Cambodia",
  },
  {
    name: "KI",
    value: "Kiribati",
  },
  {
    name: "KM",
    value: "Comoros",
  },
  {
    name: "KN",
    value: "Saint Kitts and Nevis",
  },
  {
    name: "KP",
    value: "North Korea",
  },
  {
    name: "KP",
    value: "Korea",
  },
  {
    name: "KR",
    value: "South Korea",
  },
  {
    name: "KW",
    value: "Kuwait",
  },
  {
    name: "KY",
    value: "Cayman Islands",
  },
  {
    name: "KZ",
    value: "Kazakhstan",
  },
  {
    name: "LA",
    value: "Laos",
  },
  {
    name: "LB",
    value: "Lebanon",
  },
  {
    name: "LC",
    value: "Saint Lucia",
  },
  {
    name: "LI",
    value: "Liechtenstein",
  },
  {
    name: "LK",
    value: "Sri Lanka",
  },
  {
    name: "LR",
    value: "Liberia",
  },
  {
    name: "LS",
    value: "Lesotho",
  },
  {
    name: "LT",
    value: "Lithuania",
  },
  {
    name: "LU",
    value: "Luxembourg",
  },
  {
    name: "LV",
    value: "Latvia",
  },
  {
    name: "LY",
    value: "Libya",
  },
  {
    name: "MA",
    value: "Morocco",
  },
  {
    name: "MC",
    value: "Monaco",
  },
  {
    name: "MD",
    value: "Moldova",
  },
  {
    name: "ME",
    value: "Montenegro",
  },
  {
    name: "MF",
    value: "Saint Martin",
  },
  {
    name: "MG",
    value: "Madagascar",
  },
  {
    name: "MH",
    value: "Marshall Islands",
  },
  {
    name: "MK",
    value: "Macedonia",
  },
  {
    name: "ML",
    value: "Mali",
  },
  {
    name: "MM",
    value: "Myanmar",
  },
  {
    name: "MN",
    value: "Mongolia",
  },
  {
    name: "MO",
    value: "Macau",
  },
  {
    name: "MP",
    value: "Northern Mariana Islands",
  },
  {
    name: "MQ",
    value: "Martinique",
  },
  {
    name: "MR",
    value: "Mauritania",
  },
  {
    name: "MS",
    value: "Montserrat",
  },
  {
    name: "MT",
    value: "Malta",
  },
  {
    name: "MU",
    value: "Mauritius",
  },
  {
    name: "MV",
    value: "Maldives",
  },
  {
    name: "MW",
    value: "Malawi",
  },
  {
    name: "MX",
    value: "Mexico",
  },
  {
    name: "MY",
    value: "Malaysia",
  },
  {
    name: "MZ",
    value: "Mozambique",
  },
  {
    name: "NA",
    value: "Namibia",
  },
  {
    name: "NC",
    value: "New Caledonia",
  },
  {
    name: "NE",
    value: "Niger",
  },
  {
    name: "NF",
    value: "Norfolk Island",
  },
  {
    name: "NG",
    value: "Nigeria",
  },
  {
    name: "NI",
    value: "Nicaragua",
  },
  {
    name: "NL",
    value: "Netherlands",
  },
  {
    name: "NO",
    value: "Norway",
  },
  {
    name: "NP",
    value: "Nepal",
  },
  {
    name: "NR",
    value: "Nauru",
  },
  {
    name: "NU",
    value: "Niue",
  },
  {
    name: "NZ",
    value: "New Zealand",
  },
  {
    name: "OM",
    value: "Oman",
  },
  {
    name: "PA",
    value: "Panama",
  },
  {
    name: "PE",
    value: "Peru",
  },
  {
    name: "PF",
    value: "French Polynesia",
  },
  {
    name: "PG",
    value: "Papua New Guinea",
  },
  {
    name: "PH",
    value: "Philippines",
  },
  {
    name: "PK",
    value: "Pakistan",
  },
  {
    name: "PL",
    value: "Poland",
  },
  {
    name: "PM",
    value: "Saint Pierre and Miquelon",
  },
  {
    name: "PN",
    value: "Pitcairn",
  },
  {
    name: "PR",
    value: "Puerto Rico",
  },
  {
    name: "PS",
    value: "Palestinian Territory",
  },
  {
    name: "PT",
    value: "Portugal",
  },
  {
    name: "PW",
    value: "Palau",
  },
  {
    name: "PY",
    value: "Paraguay",
  },
  {
    name: "QA",
    value: "Qatar",
  },
  {
    name: "RE",
    value: "Reunion",
  },
  {
    name: "RO",
    value: "Romania",
  },
  {
    name: "RS",
    value: "Serbia",
  },
  {
    name: "RU",
    value: "Russia",
  },
  {
    name: "RW",
    value: "Rwanda",
  },
  {
    name: "SA",
    value: "Saudi Arabia",
  },
  {
    name: "SB",
    value: "Solomon Islands",
  },
  {
    name: "SC",
    value: "Seychelles",
  },
  {
    name: "SD",
    value: "Sudan",
  },
  {
    name: "SE",
    value: "Sweden",
  },
  {
    name: "SG",
    value: "Singapore",
  },
  {
    name: "SH",
    value: "Saint Helena",
  },
  {
    name: "SI",
    value: "Slovenia",
  },
  {
    name: "SK",
    value: "Slovakia",
  },
  {
    name: "SM",
    value: "San Marino",
  },
  {
    name: "SN",
    value: "Senegal",
  },
  {
    name: "SO",
    value: "Somalia",
  },
  {
    name: "SR",
    value: "Suriname",
  },
  {
    name: "SS",
    value: "South Sudan",
  },
  {
    name: "ST",
    value: "Sao Tome and Principe",
  },
  {
    name: "SV",
    value: "El Salvador",
  },
  {
    name: "SX",
    value: "Sint Maarten",
  },
  {
    name: "SY",
    value: "Syria",
  },
  {
    name: "SZ",
    value: "Swaziland",
  },
  {
    name: "TD",
    value: "Chad",
  },
  {
    name: "TG",
    value: "Togo",
  },
  {
    name: "TH",
    value: "Thailand",
  },
  {
    name: "TJ",
    value: "Tajikistan",
  },
  {
    name: "TK",
    value: "Turkey",
  },
  {
    name: "TK",
    value: "Tokelau",
  },
  {
    name: "TL",
    value: "East Timor",
  },
  {
    name: "TM",
    value: "Turkmenistan",
  },
  {
    name: "TN",
    value: "Tunisia",
  },
  {
    name: "TO",
    value: "Tonga",
  },
  {
    name: "TR",
    value: "Turkey",
  },
  {
    name: "TR",
    value: "Turkey",
  },
  {
    name: "TT",
    value: "Trinidad and Tobago",
  },
  {
    name: "TV",
    value: "Tuvalu",
  },
  {
    name: "TW",
    value: "Taiwan",
  },
  {
    name: "TZ",
    value: "Tanzania",
  },
  {
    name: "UA",
    value: "Ukraine",
  },
  {
    name: "UG",
    value: "Uganda",
  },
  {
    name: "UM",
    value: "United States Minor Outlying Islands",
  },
  {
    name: "US",
    value: "United States",
  },
  {
    name: "UY",
    value: "Uruguay",
  },
  {
    name: "UZ",
    value: "Uzbekistan",
  },
  {
    name: "VA",
    value: "Vatican City",
  },
  {
    name: "VC",
    value: "Saint Vincent and the Grenadines",
  },
  {
    name: "VE",
    value: "Venezuela",
  },
  {
    name: "VG",
    value: "British Virgin Islands",
  },
  {
    name: "VN",
    value: "Vietnam",
  },
  {
    name: "VU",
    value: "Vanuatu",
  },
  {
    name: "WF",
    value: "Wallis and Futuna",
  },
  {
    name: "WS",
    value: "Samoa",
  },
  {
    name: "XC",
    value: "East Caribbean",
  },
  {
    name: "XK",
    value: "Kosovo",
  },
  {
    name: "YE",
    value: "Yemen",
  },
  {
    name: "YT",
    value: "Mayotte",
  },
  {
    name: "ZA",
    value: "South Africa",
  },
  {
    name: "ZM",
    value: "Zambia",
  },
  {
    name: "ZW",
    value: "Zimbabwe",
  },
];

export const phoneTechTypeList = [
  {
    name: "Voice",
    value: "1",
  },
  {
    name: "Fax",
    value: "3",
  },
  {
    name: "Mobile",
    value: "5",
  },
];

export const facilities = [
  {
    Group: "Amenities",
    name: "Hairdyer",
  },
  {
    Group: "Amenities",
    name: "Free Wifi",
  },
  {
    Group: "Amenities",
    name: "Iron Facilities",
  },
  {
    Group: "Kitchen",
    name: "Microwave",
  },
  {
    Group: "Kitchen",
    name: "Coffee Maker",
  },
];

export const measurementUnitList = [
  {
    name: "sqm",
    value: "sqm",
  },
  {
    name: "sqft",
    value: "sqft",
  },
];

export const roomTypeList = [
  {
    id: "1",
    label: "Apartment",
  },
  {
    id: "4",
    label: "Quadruple",
  },
  {
    id: "5",
    label: "Suite",
  },
  {
    id: "7",
    label: "Triple",
  },
  {
    id: "8",
    label: "Twin",
  },
  {
    id: "9",
    label: "Double",
  },
  {
    id: "10",
    label: "Single",
  },
  {
    id: "12",
    label: "Studio",
  },
  {
    id: "13",
    label: "Family",
  },
  {
    id: "25",
    label: "Dormitory",
  },
  {
    id: "26",
    label: "Bed in Dormitory",
  },
  {
    id: "29",
    label: "Holiday Home",
  },
  {
    id: "28",
    label: "Chalet",
  },
  {
    id: "31",
    label: "Villa",
  },
  {
    id: "32",
    label: "Mobile Home",
  },

  {
    id: "33",
    label: "Tent",
  },
  {
    id: "34",
    label: "Powered/Unpowered",
  },
  {
    id: "35",
    label: "King",
  },
  {
    id: "36",
    label: "Queen",
  },

  {
    id: "27",
    label: "Bungalow",
  },
];

export const mealPlanList = [
  {
    id: "1",
    label: "All inclusive",
  },
  {
    id: "2",
    label: "BreakFast",
  },
  {
    id: "3",
    label: "Lunch",
  },
  {
    id: "4",
    label: "Dinner",
  },
  {
    id: "5",
    label: "American",
  },
  {
    id: "6",
    label: "Bed & breakfast",
  },
  {
    id: "7",
    label: "Buffet breakfast",
  },
  {
    id: "8",
    label: "Caribbean breakfast",
  },
  {
    id: "9",
    label: "Continental breakfast",
  },
  {
    id: "10",
    label: "English breakfast",
  },
  {
    id: "11",
    label: "European plan",
  },
  {
    id: "12",
    label: "Family plan",
  },
  {
    id: "13",
    label: "Full board",
  },
  {
    id: "14",
    label: "Half board/modified American plan",
  },
  {
    id: "15",
    label: "Room only (Default)",
  },
  {
    id: "16",
    label: "Self catering",
  },
  {
    id: "17",
    label: "Bermuda",
  },
  {
    id: "18",
    label: "Dinner bed and breakfast plan",
  },
  {
    id: "19",
    label: "Family American",
  },
  {
    id: "20",
    label: "Modified",
  },
  {
    id: "21",
    label: "Breakfast & lunch",
  },
  {
    id: "22",
    label: "Full breakfast",
  },
];
