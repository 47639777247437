import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar, { menus } from "./SideBar";
import PopChat from "../pages/Chat/PopChat";
import Spinner from "./Spinner";
import SpinnerGlobal from "./SpinnerGlobal";
import { Routing } from "../shared/constants/routing";
import { ownerPath } from "../utils/JsonData";
import { getLoadingFailed, getLoadingSuccess, getPermissionFailed, getPermissionSuccess } from "../Reducer/authSlice";
import { permissionDetail } from "../apis/services/PermissionService";

interface Props {
  children: React.ReactNode;
}
const MainLayout: React.FC<Props> = ({ children }) => {
  // let auth = { token: true };
  const auth = useSelector((state: any) => state.auth);
  // console.log(auth, 20)
  // const auth = localStorage.getItem("access_token");
  // console.log("Auth form main layout", auth.loading);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  // const userData = JSON.parse(localStorage.getItem('userOwnerDetails'));
  // return auth?.userToken ? (

  const location = useLocation();
  // console.log(location.pathname , 26);

  useEffect(()=>{
    const el = document.querySelector('body');
    if(location.pathname === Routing?.HotelWiseOwnerReport ){
      // el.style.overflow = 'hidden';
    } else {
      el.style.overflow = 'scroll';
      handleFetchPermission();
    }
  },[location.pathname]);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  const handleFetchPermission = async () => {

    const items = JSON.parse(localStorage.getItem("userDetails"));
    dispatch(getLoadingSuccess());
    setLoading(true);
    const result = await permissionDetail(items.role_id);
    if (result.status === 200) {
      // console.log(result, 194);
      dispatch(getPermissionSuccess(result));
    dispatch(getLoadingFailed());
    setLoading(false);
    } else {
      dispatch(getPermissionFailed());
      dispatch(getLoadingFailed());
      setLoading(false);
    }
  };


  return user ? (
    <>
      {auth?.loading && <SpinnerGlobal />}
      <div className="wrapper ">
        {/* <PopChat /> */} 

        {location.pathname !== Routing?.OwnerIframeReport ? (
        <>
        <Header />
        <Sidebar />
        <main className={`main-content main-layout-background h-100vh `}>
          <Suspense fallback={<Spinner />}>
            {/* <Spinner /> */}
            {
            !loading && children}
          </Suspense>
        </main>
        </>
        ) :
        !loading && 
        children
        }

      </div>
    </>
  ) 
  // : 
  // (!user?.role_id && user?.id) ? (
  //   <>
  //   {auth?.loading && <SpinnerGlobal />}
  //   <div className="">
  //     {/* <PopChat /> */} 
  //     <Header />
  //     {/* <Sidebar /> */}

  //     <main style={{marginTop:"100px"}} className=" main-layout-background h-100vh">
  //       <Suspense fallback={<Spinner />}>
  //         {/* <Spinner /> */}
  //         {children}
  //       </Suspense>
  //     </main>
  //   </div>
  // </>
  // ) 
  : ownerPath?.includes(location?.pathname) ?
 ( <Navigate to="/owner-login" />)
  :(
    <Navigate to="/login" />
  );
};

export default MainLayout;
